@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Sentinel';
  src: url('/fonts/SentinelLight.eot'); /* IE9 Compat Modes */
  src: url('/fonts/SentinelLight.otf'),
    url('/fonts/SentinelLight.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/SentinelLight.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/SentinelLight.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/SentinelLight.svg#SentinelLight')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Hass Unica Pro';
  src: url('/fonts/NeueHassUnicaPro-Medium.otf') format('opentype'),
    url('/fonts/NeueHassUnicaPro.otf') format('opentype'),
    url('/fonts/NeueHassUnicaPro-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NB International Pro';
  src: url('/fonts/NBInternationalProRegular.otf') format('opentype'),
    url('/fonts/NBInternationalProBold.otf') format('opentype'),
    url('/fonts/NBInternationalProLight.otf') format('opentype'),
    url('/fonts/NBInternationalProMono.otf') format('opentype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NB International Pro';
  src: url('/fonts/NBInternationalProLight.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'NB International Pro Mono';
  src: url('/fonts/NBInternationalProMono.otf');
  font-weight: 400;
}

:root {
  --anchor-height: 68px;
}

html,
body {
  @apply scroll-smooth marker:font-text font-text antialiased;
  margin: 0;
  padding: 0;
}

@media (prefers-reduced-motion) {
  /* styles to apply if a user's device settings are set to reduced motion */
  html,
  body {
    scroll-behavior: auto;
  }
}

/* https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

/* any elements with an ID attribute */
.wh *[id] {
  /* add anchor height */
  scroll-margin-top: var(--anchor-height);
}

/* Disable horizontal scroll */
body {
  overflow-x: hidden;
}

@media (max-width: 639px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* break heading if width exceed parent container */
    width: auto !important;
  }
}

.aaco-header-wrapper {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

body.wh {
  @apply bg-warmer-grey;
}

body.wh .aaco-header:not(.aaco-recipe-header) .aaco-header-wrapper,
body.wh .aaco-header:not(.aaco-recipe-header) .aaco-header__image,
body.wh .aaco-header:not(.aaco-recipe-header) .aaco-header__bg-shadow {
  height: 100vh;
  min-height: 100dvh;
  min-height: -webkit-fill-available;
}

:root {
  /* Override react-spring-bottom-sheet */
  --rsbs-max-w: 400px;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
}

/* Override react-spring-bottom-sheet */
div[data-rsbs-state] > * {
  z-index: 80;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* Hide Scrollbar For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide Scrollbar For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.app-container {
  max-width: 100%;
  width: 100vw;
  transition: opacity 300ms ease-out;
  opacity: 1;
}
.app-container.transitioning {
  opacity: 0;
  pointer-events: none;
}

a {
  color: inherit;
  text-decoration: none;
}

/** Quick solution */
.swiper.aaco-swiper {
  @apply overflow-visible;
}
.aaco-carousel-featured .swiper {
  @apply h-full;
}
.aaco-carousel-featured .swiper .swiper-slide {
  @apply h-full;
}

.aaco-carousel-featured .swiper .swiper-wrapper {
  height: 100% !important;
}
.aaco-carousel-featured .aaco-card__image {
  height: 100% !important;
}
.aaco-carousel-featured .aaco-carousel-card__slide {
  height: 100% !important;
}

.swiper .swiper-pagination-bullet {
  @apply bg-dark-warm-grey opacity-100 w-1.5 h-1.5 transition-all;
}
.swiper .swiper-pagination-bullet + .swiper-pagination-bullet {
  @apply ml-2;
}
.swiper .swiper-pagination-bullet-active {
  @apply w-2.5 h-2.5 bg-acacia;
}

/** Pagination **/
.aaco-pagination {
  @apply flex items-center gap-x-4;
}

body.wh .aaco-pagination li a {
  @apply font-text font-body text-body text-dark-warm-grey px-2 py-1;
}

body.wh .aaco-pagination li.selected a {
  @apply font-text font-body-bold text-acacia border-b-[1.5px] border-b-acacia;
}

svg.cuts-outline {
  pointer-events: all;
  cursor: pointer;
}
#outlines path {
  transition: all 0.5s;
  fill-opacity: 0;
}

@media screen(sm) {
  .container {
    max-width: 100%;
  }
}

@media screen(md) {
  .aaco-two-column-layout .aaco-left-column .aaco-content-grid,
  .aaco-two-column-layout .aaco-left-column .header-container {
    padding-right: 2rem;
    margin-right: unset;
  }

  .aaco-two-column-layout .aaco-right-column .aaco-content-grid {
    padding-left: 2rem;
    margin-left: unset;
  }
}

@media screen(2xl) {
  .container {
    max-width: 1536px;
  }

  .aaco-two-column-layout .md\:col-span-4 .header-container:not(.no-side),
  .aaco-two-column-layout .md\:col-span-4 .aaco-content-grid:not(.no-side) {
    max-width: 768px;
  }

  .aaco-two-column-layout .md\:col-span-3 .header-container:not(.no-side),
  .aaco-two-column-layout .md\:col-span-3 .aaco-content-grid:not(.no-side) {
    max-width: 576px;
  }

  .aaco-two-column-layout .md\:col-span-5 .header-container:not(.no-side),
  .aaco-two-column-layout .md\:col-span-5 .aaco-content-grid:not(.no-side) {
    max-width: 960px;
  }
}

@media screen(xl) {
  body.wh [id='Column Our Land'],
  body.wh [id='Column Our Story'],
  body.wh [id='Column Content Breeds'] {
    @apply !gap-x-0;
  }
}

.aaco-header__bg-shadow {
  background: rgba(0, 0, 0, 0.2);
}

.aaco-header__bg-shadow__inset {
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  height: 180px;
}

/*
* Homepage navbar type is sticky (fixed)
* so we have to make the z-index for navbar & navbar menu open high
*/
body.wh .aaco-header-home .aaco-navigation,
body.wh .aaco-header-home #navigation__wrapper > div {
  z-index: 100;
}

body.wh .aaco-header-home #navigation__wrapper > div {
  position: fixed !important;
}

body.wh
  .aaco-header-home
  #navigation__wrapper
  > div
  > #navigation.nav-menu
  > div {
  position: fixed !important;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .aaco-header__moving-logo {
    @apply !px-[14%];
  }
}

.aaco-list.list-decimal .aaco-list-item {
  padding-bottom: 1rem;
  padding-left: 1rem;
  margin-left: 2rem;
}

.aaco-list.list-decimal .aaco-list-item p {
  display: inline;
}

body.wh .aaco-links-list span {
  @apply !font-text;
}

body.wh .aaco-two-column-layout .aaco-column .aaco-block-text__wrapper {
  @apply p-0;
}

body.wh .aaco-two-column-layout .aaco-column .block-quote-wrap {
  @apply !justify-start;
}

body.wh [id='Column Our Land'] .aaco-left-column .block-quote-text,
body.wh [id='Column Our Story'] .aaco-left-column .block-quote-text,
body.wh [id='Column Content Breeds'] .aaco-left-column .block-quote-text,
body.wh
  [id='Column Content Animale Walfare']
  .aaco-left-column
  .block-quote-text {
  @apply !w-full;
}

body.wh [id='Column Our Land'],
body.wh [id='Column Our Story'],
body.wh [id='Column Content Breeds'],
body.wh [id='Column Content Animale Walfare'] {
  @apply gap-x-8;
}

body.wh
  [id='Column Our Land']
  .aaco-left-column
  p.aaco-paragraph--is-blockquote
  span,
body.wh
  [id='Column Our Story']
  .aaco-left-column
  p.aaco-paragraph--is-blockquote
  span,
body.wh
  [id='Column Content Breeds']
  .aaco-left-column
  p.aaco-paragraph--is-blockquote
  span,
body.wh
  [id='Column Content Animale Walfare']
  .aaco-left-column
  p.aaco-paragraph--is-blockquote
  span {
  @apply !font-h4-mobile !text-h4-mobile !leading-[38px];
}

body.wh
  [id='Column Our Land']
  .aaco-right-column
  p.aaco-paragraph--is-text
  span,
body.wh [id='Column Our Land'] .aaco-left-column p.aaco-paragraph--is-text span,
body.wh
  [id='Column Our Story']
  .aaco-right-column
  p.aaco-paragraph--is-text
  span,
body.wh
  [id='Column Our Story']
  .aaco-left-column
  p.aaco-paragraph--is-text
  span {
  @apply font-body text-body;
}

/* change all article text size */
body.wh .aaco-block-text__wrapper p.aaco-paragraph--is-text span {
  @apply text-body-large;
}

.wh picture img {
  @apply border border-solid border-black;
}

.aaco-story-recipe-steps .aaco-list.aaco-list--unordered .aaco-list-item,
.aaco-story-recipe-steps .aaco-list.aaco-list--ordered .aaco-list-item {
  @apply pb-0 pl-0 ml-0;
}

.aaco-list--ordered .aaco-list-item::marker {
  @apply font-heading text-h5 text-acacia;
  display: inline-block;
}

.aaco-story-recipe-steps
  .aaco-list.aaco-list--unordered
  .aaco-list-item::marker,
.aaco-story-recipe-steps .aaco-list.aaco-list--ordered .aaco-list-item::marker {
  @apply font-text font-body text-body text-acacia;
}

.pill-action,
.aaco-cta,
.aaco-cta span,
.aaco-cta .aaco-icon-container,
.aaco-cta .aaco-icon {
  transition: all 500ms;
}

.wh .aaco-anchor-navigation .aaco-cta__primary-wh {
  @apply border-none hover:bg-transparent hover:underline hover:underline-offset-[5px] p-0;
}

.wh .aaco-cta:hover .aaco-card__title,
.wh .aaco-anchor-navigation .aaco-cta.active > span {
  @apply underline underline-offset-[5px] decoration-[1px] decoration-black;
}

.wh .aaco-anchor-navigation {
  @apply flex content-center items-center px-8 md:px-16 z-[75] w-full h-[68px] overflow-x-scroll md:overflow-x-auto;
}

.wh .aaco-anchor-navigation.aaco-anchor-navigation--hidden {
  height: 0 !important;
}

.wh .aaco-anchor-navigation:not(.aaco-anchor-navigation--moving) {
  @apply fixed top-0 left-0;
}

.wh .aaco-pill {
  @apply uppercase bg-eucalyptus border-black text-black hover:border-black !important;
}

.wh .aaco-cta:hover:not(.link-invert) .aaco-icon-container {
  @apply bg-eucalyptus border-none;
}

.wh .aaco-cta:hover:not(.link-invert) .aaco-icon {
  @apply text-white;
}
.wh button.aaco-cta:not(.link-invert):hover {
  @apply bg-eucalyptus;
}
.wh button.aaco-cta:not(.link-invert):hover span {
  @apply text-white;
}

.wh .aaco-column.bg-majesty .aaco-cta:hover span,
.wh .aaco-cta.link-invert:hover:not(button) span {
  @apply underline underline-offset-[5px] decoration-[1.5px] decoration-acacia;
}
.wh .aaco-column.bg-majesty .aaco-cta:hover .aaco-icon-container,
.wh .aaco-cta.link-invert:hover .aaco-icon-container {
  @apply bg-white border-warm-grey shadow;
}
.wh .aaco-column.bg-majesty .aaco-cta:hover .aaco-icon,
.wh .aaco-cta.link-invert:hover .aaco-icon {
  @apply text-acacia;
}
.wh .aaco-column.bg-majesty button.aaco-cta:hover,
.wh button.aaco-cta.link-invert:hover {
  @apply bg-white border-1 border-warm-grey;
}
.wh .aaco-column.bg-majesty button.aaco-cta:hover span,
.wh button.aaco-cta.link-invert:hover span {
  @apply text-dark-warm-grey;
}

.wh .aaco-station-detail__description .aaco-carousel-card .aaco-cta {
  @apply text-black no-underline;
}

.wh .aaco-station-detail__description .aaco-carousel-card .aaco-cta:hover,
.wh
  .aaco-station-detail__description
  .aaco-carousel-card
  .aaco-cta
  > span:hover {
  @apply underline underline-offset-[5px] decoration-[1.5px] decoration-acacia;
}

.wh .aaco-two-column-layout .aaco-section-header__cta .aaco-cta span {
  @apply text-acacia;
}

.wh
  .aaco-two-column-layout
  .aaco-section-header__cta
  .aaco-cta:hover
  .aaco-icon-container,
.wh
  .aaco-two-column-layout
  .aaco-section-header__cta
  .aaco-cta
  .aaco-icon-container:hover {
  @apply bg-acacia border-none;
}

.wh
  .aaco-two-column-layout
  .aaco-section-header__cta
  .aaco-cta:hover
  .aaco-icon-container
  svg,
.wh
  .aaco-two-column-layout
  .aaco-section-header__cta
  .aaco-cta
  .aaco-icon-container:hover
  svg {
  @apply text-white;
}

.text-button {
  letter-spacing: 0.05em;
}

.aaco-media--video-lightbox-container {
  z-index: 100;
}
.aaco-media--video-lightbox iframe {
  width: 95% !important;
  height: 95% !important;
}

.aaco-story__content.alt {
  margin-left: calc(50vw - 769px);
}

.wh .aaco-story__content .aaco-story__content__title-html h3 {
  @apply text-black md:text-white mx-auto;
}

.aaco-carousel-normal .aaco-carousel-card__slide {
  height: 40vh;
  width: 90vw;
}

body.wh .aaco-carousel-normal .swiper-slide,
body.wh .aaco-carousel-normal .aaco-card__image {
  @apply h-[415px] md:h-[415px] w-[330px];
}
/*
body.wh .aaco-carousel-normal .swiper {
  @apply -ml-[20px] md:-ml-0;
} */

/* body.wh .aaco-carousel-normal .swiper-slide {
  margin-right: 40px !important;
} */

body.wh .aaco-carousel-normal .aaco-carousel-card__content .swiper {
  @apply h-[170px];
}

/* body.wh .aaco-carousel-normal .aaco-carousel-card__image,
body.wh .aaco-carousel-normal .swiper,
body.wh .aaco-carousel-normal .aaco-card__image {
  @apply h-[415px] md:h-[415px];
} */

.aaco-carousel-normal .swiper-slide {
  overflow-x: visible;
  overflow-y: hidden;
  visibility: hidden;
}
.aaco-carousel-normal .swiper-slide.swiper-slide-active,
.aaco-carousel-normal .swiper-slide.swiper-slide-next,
.aaco-carousel-normal .swiper-slide.swiper-slide-next + .swiper-slide {
  visibility: visible;
}

body.wh .aaco-carousel-normal .aaco-carousel-card__content h5 {
  @apply text-black;
}

.aaco-carousel-hero .aaco-carousel-card__slide {
  height: auto;
  width: 90vw;
}

body.wh .aaco-carousel-normal__pagination,
body.wh .aaco-carousel-hero__pagination .swiper-pagination-bullet {
  @apply font-pro-mono text-body text-acacia;
}

@media screen(md) {
  .aaco-carousel-normal .aaco-carousel-card__slide {
    height: 40vh;
    max-width: 90vw;
    width: auto;
  }

  .aaco-carousel-hero .aaco-carousel-card__slide {
    height: 70vh;
    max-height: 560px;
    max-width: 90vw;
    width: auto;
  }
}

.aaco-column.bg-majesty,
.aaco-column.bg-majesty .text-majesty,
.aaco-column.bg-majesty .text-black {
  @apply text-white;
}
.aaco-column.bg-majesty .text-grey-6 {
  @apply text-majesty-50;
}

/* Input Floating label */
.aaco-input.floating-label {
  @apply relative z-0 flex items-end pt-[14px] shadow-input-form pb-2.5;
  width: 100%;
}

.aaco-input.floating-label:not(.aaco-text-area) {
  @apply h-[50px];
}

.aaco-input.floating-label.aaco-text-area {
  @apply min-h-[50px];
}

.aaco-input.floating-label.aaco-input--is-focus {
  @apply shadow-input-form-active;
}

.aaco-input.floating-label label {
  @apply absolute font-text font-button text-button duration-300 transform -translate-y-7 top-[25px] z-10 origin-[0];
}

.aaco-input.floating-label.aaco-input--is-focus > label,
.aaco-input.floating-label.aaco-input--is-focus > .aaco-select-selected + label,
.aaco-input.floating-label.aaco-input--is-focus > .grow-wrap + label {
  @apply text-acacia -translate-y-7 top-[25px];
}

.aaco-input.floating-label:not(.aaco-input--is-focus) > label {
  @apply text-dark-warm-grey;
}

.aaco-input.floating-label:not(.aaco-input--is-floating) > .grow-wrap + label,
.aaco-input.floating-label:not(.aaco-input--is-floating)
  > .aaco-select-selected
  + label {
  @apply top-[52px];
}

/* Auto resize textarea */
.aaco-text-area > .grow-wrap {
  word-break: break-all;
  display: grid;
}
.aaco-text-area > .grow-wrap::after {
  /* To preventy jumpy behavior */
  content: attr(data-replicated-value) ' ';
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.aaco-text-area > .grow-wrap > textarea {
  /* Avoid resize to keep the sizing */
  resize: none;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.aaco-text-area > .grow-wrap > textarea,
.aaco-text-area > .grow-wrap::after {
  font: inherit;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.shown-print {
  display: none;
}

@media print {
  html,
  body {
    height: 100vh;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .shown-print {
    display: block !important;
  }

  .hidden-print,
  .hidden-print *,
  #you-may-also-like,
  [aria-label='You may also like'],
  [aria-label='You may also like'] * {
    height: 1px;
    display: none !important;
  }
}

#map-navigation,
#pattern0 > use {
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.aaco-navigation__page__minimap {
  @apply block w-fit z-30 pl-8 h-min absolute;
  /* visible map's part - 30px - minimap height */
  margin-top: calc(40vh - 30px - 91px);
}

@media (min-width: 1600px) and (max-width: 1920px) {
  body.wh
    .aaco-detail-station__body__white
    .aaco-carousel-normal
    .aaco-carousel-card__content {
    @apply !-left-[3rem];
  }
}
@media (min-width: 1921px) and (max-width: 2650px) {
  body.wh
    .aaco-detail-station__body__white
    .aaco-carousel-normal
    .aaco-carousel-card__content {
    @apply !-left-[0.5rem];
  }
}

body.wh
  .aaco-detail-station__body__white
  .aaco-carousel-normal
  .aaco-carousel-card__content {
  @apply left-[8%] sm:-left-[50%] md:-left-[60%] lg:-left-[65%] xl:-left-[85%] md:w-[330px];
}

body.wh .aaco-carousel-normal .aaco-carousel-card__content {
  @apply md:w-[330px] lg:left-2 xl:-left-[.45rem];
}

/* Lead Generation */
body.wh
  .aaco-lead-generation
  input[type='checkbox']
  + p.aaco-paragraph.aaco-paragraph--is-text
  span {
  @apply font-tag text-tag;
}

/* Two Column Block Text */
body.wh
  .aaco-two-column-block-text
  p.aaco-paragraph.aaco-paragraph--is-text
  span {
  @apply font-tag text-tag;
}

/* Story Recipe */
body.wh .aaco-story-recipe-steps ol > li::before,
body.wh .aaco-story-recipe-steps ol > li::before {
  content: 'Step ' counter(list-item) '. ';
  @apply absolute font-text font-body-large-bold text-body-large-bold text-black !important;
  left: 0rem;
}

body:not(.dd-en) .aaco-story-recipe-steps ol > li,
body:not(.dd-kr) .aaco-story-recipe-steps ol > li {
  list-style-type: none;
  @apply relative pl-16 md:pl-20 !important;
}

body:not(.dd-en) .aaco-story-recipe-steps ol > li > p > span,
body:not(.dd-kr) .aaco-story-recipe-steps ol > li > p > span,
body:not(.dd-en) .aaco__ingredient ul > li > p > span,
body:not(.dd-kr) .aaco__ingredient ul > li > p > span {
  @apply text-black !important;
}

/* Section you might also like */
.wh #you-may-also-like .aaco-section-header__title-wrapper {
  @apply mb-14;
}
.wh #you-may-also-like .aaco-section-header__cta {
  @apply mt-[16px];
}
@media screen(md) {
  .wh #you-may-also-like .aaco-section-header__title-wrapper {
    @apply mb-11;
  }
  .wh #you-may-also-like .aaco-section-header__cta {
    @apply mt-9;
  }
}

/* Search page */
body.wh .aaco-search-page .aaco-content-grid {
  @apply mb-[50px];
}
body.wh .aaco-search-page .aaco-content-grid > div > div {
  @apply gap-y-[37px];
}

@media screen(md) {
  body.wh .aaco-search-page .aaco-content-grid {
    @apply mb-20;
  }

  body.wh .aaco-search-page .aaco-content-grid > div > div {
    @apply gap-y-[120px];
  }
}

[aria-label='Restaurants Grid'] div .grid {
  @apply mx-auto my-0;
}

/* ==================== */
/* START: Marrow main page   */
/* ==================== */
body.wh .app-container[aria-label='the-marrow'] .aaco-header {
  @apply mb-0;
}

body.wh [aria-label='the-marrow-header'] {
  @apply !pt-10 !pb-0;
}

body.wh
  [aria-label='the-marrow-header']
  .wh-section-header
  .aaco-section-header__divider {
  @apply !pt-[22px] md:!pt-6 !m-0;
}

body.wh #marrow-header-hero {
  @apply !py-10;
}

body.wh
  .app-container[aria-label='the-marrow']
  .aaco-anchor-navigation.aaco-anchor-navigation--moving {
  @apply mb-[92px] md:mb-[134px];
}

/* Start: anchor navigation scroll on marrow & products */
body.wh .app-container[aria-label='the-marrow'] .aaco-header-wrapper {
  height: calc(100vh - var(--anchor-height));
  margin-bottom: 0;
}

body.wh
  .app-container[aria-label='the-marrow']
  .aaco-header-wrapper
  + .aaco-anchor-navigation--scroll {
  margin-top: calc(var(--anchor-height) - 4px);
}
/* End: anchor navigation scroll */

body.wh [id='Marrow P1'] {
  @apply container;
  margin: 0 auto;
}

body.wh [aria-label='Restaurants Grid'] .aaco-cta span {
  @apply px-2 py-[2px];
}

body.wh [aria-label='Restaurants Grid'] .aaco-card__cta {
  @apply pl-2;
}

#Marrow-P4 .aaco-left-column {
  padding: 0;
}

@media (min-width: 1280px) and (max-width: 1532px) {
  body.wh [id='Marrow P1'] {
    padding: 0 !important;
    max-width: initial !important;
    margin: 0;
  }

  body.wh
    [id='Marrow P1']
    .aaco-left-column
    .aaco-section-header
    .aaco-section-header__divider {
    width: 100px;
  }

  body.wh #Marrow-P3,
  body.wh #Marrow-P4 {
    padding-left: 0 !important;
    max-width: initial !important;
  }

  body.wh [id='Marrow P1'] .aaco-left-column {
    padding-left: 4rem;
  }

  body.wh [id='Marrow P1'] .aaco-right-column .aaco-content-grid {
    padding-right: 0 !important;
  }

  body.wh [id='Marrow P2'] {
    max-width: initial !important;
    padding: 0 4rem;
  }

  body.wh #Marrow-P4 .aaco-right-column,
  body.wh #Marrow-P3 .aaco-right-column {
    padding-left: 10rem;
  }

  body.wh #Marrow-P3 .aaco-right-column {
    padding-right: 0;
  }

  body.wh #Marrow-P3 .aaco-right-column div .grid .aaco-card__image,
  body.wh #Marrow-P4 .aaco-right-column div .grid .aaco-card__image {
    height: 284px;
    width: 380px;
  }

  body.wh #Marrow-P3 .aaco-left-column .aaco-content-grid {
    padding-right: 0;
  }

  body.wh #Marrow-P3 .aaco-left-column div .grid .aaco-card__image {
    height: 340px;
  }

  body.wh #Marrow-P3 .aaco-right-column div .grid,
  body.wh #Marrow-P4 .aaco-right-column div .grid {
    max-width: 380px;
  }

  body.wh #Marrow-P3 .aaco-left-column,
  body.wh #Marrow-P4 .aaco-left-column,
  body.wh #Marrow-P3 .aaco-right-column,
  body.wh #Marrow-P4 .aaco-right-column {
    @apply col-span-5;
  }
}

#Marrow-P3 .aaco-section-header,
#Marrow-P3 .aaco-left-column,
#Marrow-P3 .aaco-section-header .header-container,
#Marrow-P3 .content-grid-wrapper,
[aria-label='Marrow P3 Left Chef Main'] {
  @apply p-0 !important;
}

#Marrow-P3 .aaco-left-column .aaco-section-header .header-container {
  @apply md:pr-0 pb-0 !important;
}

#Marrow-P3
  .aaco-left-column
  .aaco-section-header
  .header-container
  .wh-section-header {
  @apply bg-eucalyptus px-8 pt-8 pb-4 md:pt-16 md:pb-4 md:px-12 border-black border-solid border-l-1 border-r-1 border-t-1;
}

#Marrow-P3 .aaco-left-column .aaco-content-grid .wh-grid-wrap {
  @apply bg-eucalyptus px-8 pt-4 pb-8 md:pb-12 md:pt-4 md:px-12 border-black border-solid border-b-1 border-l-1 border-r-1;
}

#Marrow-P3 [aria-label='Marrow P3 Left Chef Main'] .aaco-cta span {
  @apply font-heading text-h6 font-h6 md:text-h5 md:font-h5 text-black normal-case;
}

#Marrow-P3 .aaco-left-column .aaco-card__image {
  @apply h-[418px] md:h-[50vh] !important;
}

body.wh [aria-label='the-marrow'] .aaco-card__content {
  padding: 0;
  border: none;
}

body.wh #Marrow-P3 .aaco-right-column .aaco-card__content .aaco-cta span,
body.wh #Marrow-P4 .aaco-right-column .aaco-card__content .aaco-cta span,
body.wh #Marrow-P4 .aaco-left-column .aaco-card__content .aaco-cta span {
  @apply font-h6-mobile md:font-h6 text-h6-mobile md:text-h6 normal-case !important;
}

body.wh #Marrow-P3 .aaco-right-column .aaco-card .pill-wrapper,
body.wh #Marrow-P4 .aaco-right-column .aaco-card .pill-wrapper,
body.wh #Marrow-P4 .aaco-left-column .aaco-card .pill-wrapper {
  @apply bottom-8;
}

@media (min-width: 1280px) {
  body.wh
    [id='Marrow P1']
    .aaco-left-column
    .aaco-section-header
    .aaco-section-header--section {
    padding-left: 0;
  }

  /* [id='Marrow P1'] .aaco-left-right .aaco-content-grid {
    padding-right: 0;
  } */

  body.wh [id='Marrow P1'] div .grid .aaco-card__image {
    height: 683px;
  }

  body.wh [id='Marrow P2'] .aaco-column .aaco-content-grid {
    padding: 0;
  }

  body.wh [id='Marrow P2'] .aaco-right-column {
    padding-right: 0;
    padding-left: 2rem;
  }

  body.wh [id='Marrow P2'] div .grid .aaco-card__image {
    height: 550px;
  }

  [aria-label='Marrow P4 Left Grid'] .w-full {
    width: 708px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  body.wh [id='Marrow P1'],
  body.wh [id='Marrow P2'] {
    padding: 0 !important;
  }

  body.wh [id='Marrow P1'] div .grid .aaco-card__image {
    width: 610px;
  }

  body.wh [id='Marrow P2'] .aaco-right-column {
    padding: 0 !important;
  }

  body.wh #Marrow-P3 .aaco-right-column div .grid .aaco-card__image,
  body.wh #Marrow-P4 .aaco-right-column div .grid .aaco-card__image {
    height: 284px;
    width: 380px;
  }

  body.wh #Marrow-P3 .aaco-left-column .aaco-content-grid {
    padding-right: 0;
  }

  body.wh #Marrow-P3 .aaco-left-column div .grid .aaco-card__image {
    height: 340px;
  }

  body.wh #Marrow-P3 .aaco-right-column div .grid,
  body.wh #Marrow-P4 .aaco-right-column div .grid {
    max-width: 380px;
  }
}
@media (min-width: 768px) {
  [aria-label='Marrow P3 Right'] {
    padding: 20rem 0 0;
  }

  body.wh .aaco-carousel-featured .aaco-carousel-hero__cta .aaco-cta {
    @apply p-0;
  }

  body.wh #Marrow-P3 .aaco-right-column,
  body.wh #Marrow-P4 .aaco-right-column {
    padding: 0;
  }

  [aria-label='Marrow P3 Right'] .w-full,
  [aria-label='Marrow P4 Right Recipes'] .w-full {
    max-width: 380px;
  }

  [aria-label='featured-recipes'] {
    @apply !p-0;
  }

  [aria-label='Marrow P3 Right'] img,
  [aria-label='Marrow P4 Right Recipes'] img {
    max-height: 288px;
  }

  [aria-label='Marrow P3 Right'] .aaco-card__image,
  [aria-label='Marrow P4 Right Recipes'] .aaco-card__image {
    height: auto;
  }

  body.wh .aaco-cuts-illustrations .swiper-slide {
    margin-right: 55px !important;
    width: fit-content !important;
  }
}

.aaco-cta__primary-wh {
  @apply border-[1px] border-solid border-black hover:bg-leucadendron w-max;
}

[aria-label='Marrow P3 Right'],
[aria-label='Marrow P4 Right Recipes'] {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

[aria-label='Marrow P4 Left Grid'] {
  padding: 0;
}

[aria-label='Marrow P3 Left Chef Main'] div .grid div,
[aria-label='Marrow P4 Left Grid'] div .grid div {
  width: 100%;
}

[aria-label='Marrow P4 Left Grid']
  div
  .grid
  div
  .aaco-card
  .absolute
  .aaco-pill {
  width: auto;
}

/* @media screen(md) {
  [aria-label='Marrow P4 Left Grid'] {
    padding: 0 0 2rem 4rem !important;
  }
} */

@media (max-width: 768px) {
  body.wh #Marrow-P4 .aaco-left-column,
  body.wh #Marrow-P4 .aaco-right-column {
    @apply mb-0 pb-0 col-span-4;
  }

  body.wh #Marrow-P3 .aaco-left-column,
  body.wh #Marrow-P3 .aaco-right-column {
    @apply pb-0 col-span-4;
  }

  body.wh #Marrow-P4 .aaco-left-column .aaco-content-grid {
    @apply mb-[75px];
  }
  body.wh .aaco-story-product .aaco-header__image picture img {
    @apply h-[418px];
  }

  body.wh .aaco-story-featured-asset .aaco-media--video-lightbox picture img,
  body.wh .aaco-story-featured-asset .aaco-story-wrap-divide {
    @apply h-[234px];
  }

  body.wh .aaco-story-featured-asset .aaco-story__content,
  body.wh .aaco-story-featured-asset .aaco-story__content .font-heading {
    @apply my-[.5rem];
  }

  [aria-label='featured-recipes'] {
    @apply !pt-16 !pb-0;
  }

  [aria-label='Marrow P3 Right'] .aaco-card__image,
  [aria-label='Marrow P4 Right Recipes'] .aaco-card__image,
  [aria-label='Marrow P4 Left Grid'] .aaco-card__image {
    @apply h-[253px];
  }

  body.wh .aaco-story-featured-asset .aaco-story__content h1 {
    display: -webkit-box;
    /* max-width: 200px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

[aria-label='featured-recipes'] .header-container .wh-section-header h3 {
  @apply !font-h4-mobile !text-h4-mobile md:font-h4 md:text-h4;
}

[aria-label='featured-recipes'] .header-container {
  @apply !p-0;
}

[aria-label='featured-recipes']
  .header-container
  .wh-section-header
  .aaco-section-header__divider {
  @apply !hidden;
}

/* ==================== */
/* END: Marrow main page     */
/* ==================== */

/* ==================== */
/* START: Recipe page   */
/* ==================== */
/* Select all two column content that are siblings of recipe header */
body.wh
  .aaco-recipe-header
  ~ div
  > .aaco-two-column-layout#recipe-introduction
  > .aaco-right-column
  > div {
  @apply mb-0;
}

body.wh
  .aaco-recipe-header
  ~ div
  > .aaco-two-column-layout#recipe-introduction
  > .aaco-right-column {
  @apply flex flex-col mb-[99px] gap-y-[7px];
}

/* body.wh .aaco-recipe-header-wrapper .aaco-anchor-navigation__container {
  @apply mb-8 md:mb-10;
} */

body.wh [id='recipe-introduction'] {
  @apply pt-8 md:pt-10;
}

body.wh .aaco-recipe-header__container .aaco-header {
  @apply mb-0;
}

body.wh
  .aaco-recipe-header__container
  .aaco-anchor-navigation.aaco-anchor-navigation--moving {
  @apply mb-[70px] md:mb-20;
}

/* Recipe ingredients */
body.wh .aaco__ingredient-section .aaco-section-header__title-wrapper {
  @apply mb-[46px];
}
body.wh
  .aaco__ingredient-section
  .aaco-section-header__title-wrapper
  > div
  > div {
  @apply pl-0;
}
body.wh .aaco__ingredient .aaco-list--unordered .aaco-list-item::marker {
  @apply text-acacia;
  line-height: 20px;
  display: inline-block;
  text-align: center;
}

/* You might also like section on recipe page */
body.wh .aaco__ingredient-section ~ .aaco-content-grid > div > div {
  @apply gap-y-14;
}
/* To keep the same horizontal margin as other content in the recipe */
body.wh .aaco__ingredient-section ~ .aaco-content-grid > div > div > div {
  @apply gap-y-14 xl:w-auto;
}

/* Recipe method */
body.wh
  .aaco__ingredient-section
  + .aaco-section-header
  .aaco-section-header__title-wrapper {
  @apply mb-[49px];
}

body.wh .aaco-story-recipe-steps .aaco-recipe-image picture {
  @apply flex;
}

@media screen(md) {
  body.wh
    .aaco-recipe-header
    ~ div
    > .aaco-two-column-layout#recipe-introduction
    > .aaco-right-column {
    @apply mb-[120px] gap-y-[27px];
  }
  /* Recipe ingredients */
  body.wh .aaco__ingredient-section__header > div {
    @apply m-0 p-0;
  }
  body.wh
    .aaco__ingredient-section
    .aaco-section-header
    .aaco-section-header__title-wrapper {
    @apply mb-[42px] p-0;
    overflow: visible !important;
  }
  body.wh .aaco__ingredient ul {
    @apply pl-[30px];
  }

  /* Recipe method  */
  body.wh
    .aaco__ingredient-section
    + .aaco-section-header
    .aaco-section-header__title-wrapper {
    @apply mb-[99px];
    overflow: visible !important;
  }
}

body.wh
  .aaco__ingredient-section
  .wh-section-header
  .aaco-section-header__divider {
  @apply mt-[18px] md:mt-6;
}

body.wh .aaco__ingredient-section__header .header-container {
  @apply !p-0;
}
/* ==================== */
/* END: Recipe page     */
/* ==================== */

/* ======================== */
/*  START: masterclass page */
/* ======================== */
/* section header masterclass */
body.wh #australian-wagyu-masterclass .aaco-section-header__title-wrapper {
  @apply mb-[87px] md:mb-[69px];
}
/* ======================== */
/*  END: masterclass page   */
/* ======================== */

/* ======================================== */
/*  START: learn subpages & marrow subpages */
/* ======================================== */
/* filter */
body.wh #techniques ~ div.aaco-filters,
body.wh #cuisines ~ div.aaco-filters,
body.wh #cuts ~ div.aaco-filters,
body.wh #chefs ~ div.aaco-filters,
body.wh #recipes ~ div.aaco-filters,
body.wh #stories-heading ~ div.aaco-filters {
  /* The padding x are container setting */
  /* Listed to avoid circular depedencies err */
  @apply max-w-full 2xl:max-w-[1536px] px-[1.7rem] md:px-[3rem] lg:px-[4rem] xl:px-[4rem] 2xl:px-[4rem] mx-auto md:gap-[30px] mb-[59px] md:mb-[54px];
}

body.wh #techniques .aaco-section-header__title-wrapper,
body.wh #cuisines .aaco-section-header__title-wrapper,
body.wh #cuts .aaco-section-header__title-wrapper {
  @apply mb-[61px];
}

body.wh #techniques .aaco-section-header__title-wrapper,
body.wh #cuisines .aaco-section-header__title-wrapper,
body.wh #cuts .aaco-section-header__title-wrapper,
body.wh #chefs .aaco-section-header__title-wrapper,
body.wh #recipes .aaco-section-header__title-wrapper,
body.wh #stories-heading .aaco-section-header__title-wrapper {
  @apply mb-[112px] md:mb-[94px];
}

body.wh #techniques ~ div.aaco-filters > span,
body.wh #cuisines ~ div.aaco-filters > span,
body.wh #cuts ~ div.aaco-filters > span,
body.wh #chefs ~ div.aaco-filters > span,
body.wh #recipes ~ div.aaco-filters > span,
body.wh #stories-heading ~ div.aaco-filters > span {
  @apply mr-0;
}
/* grid */
/* pagination & grid */
body.wh #techniques ~ div.aaco-content-grid,
body.wh #cuisines ~ div.aaco-content-grid,
body.wh #cuts ~ div.aaco-content-grid,
body.wh #chefs ~ div.aaco-content-grid,
body.wh #recipes ~ div.aaco-content-grid,
body.wh #stories-heading ~ div.aaco-content-grid {
  @apply mb-[51px] md:mb-[87px];
}
/* inside grid */
body.wh #techniques ~ div.aaco-content-grid > div > .grid,
body.wh #cuisines ~ div.aaco-content-grid > div > .grid,
body.wh #cuts ~ div.aaco-content-grid > div > .grid,
body.wh #chefs ~ div.aaco-content-grid > div > .grid,
body.wh #recipes ~ div.aaco-content-grid > div > .grid,
body.wh #stories-heading ~ div.aaco-content-grid > div > .grid {
  @apply gap-y-[60px] md:gap-y-[120px];
}

/* ============================================ */
/*  END: the marrow subpages & learn subpages   */
/* ============================================ */

/* ========================== */
/*  START: ourstory pages     */
/* ========================== */

body.wh
  #our-westholme-story
  .aaco-section-header--page
  .aaco-section-header__title-wrapper
  .aaco-section-header__subtext {
  @apply mt-[49px] md:mt-[42px];
}

body.wh
  #our-westholme-story
  .aaco-section-header--page
  .aaco-section-header__title-wrapper {
  @apply mb-[63px] md:mb-[126px];
}

body.wh
  #our-westholme-story
  ~ .aaco-story.aaco-story-featured-asset
  ~ .aaco-anchor-navigation--scroll {
  @apply mb-[145px] md:mb-[120px];
}

/* our stations margin to map */
body.wh #our-stations .aaco-section-header__title-wrapper {
  @apply mb-[73px] md:mb-12;
}

body.wh .aaco-map-preview {
  @apply mb-[93px] md:mb-[120px];
}

body.wh #animal-welfare .aaco-section-header__title-wrapper,
body.wh #animal-welfare + .aaco-story-featured-asset,
body.wh #tasting-notes + .aaco-story-featured-asset {
  @apply mb-[58px] md:mb-[65px];
}

body.wh #our-westholme-story ~ * .aaco-story-featured-asset {
  @apply mb-[58px] md:mb-[54px];
}

/* carousel align left */
body.wh #our-westholme-story ~ * .aaco-carousel__content__title ~ p {
  @apply pl-0;
}
/* ========================== */
/*  END: ourstory pages     */
/* ========================== */

/* ========================== */
/*  START: our cuts pages     */
/* ========================== */
/* content grid wrapper */
body.wh .aaco-cut-grid {
  @apply py-0;
}

body.wh .aaco-cut-grid > div > div {
  @apply mb-0 pb-0;
}

body.wh .aaco-cut-grid .aaco-content-grid {
  @apply pb-[60px] md:pb-[120px] mb-0;
}

/* For the card grid in cut page */
/* body.wh .aaco-cut-grid .aaco-content-grid .grid-cols-content-grid-overflow-x {
  @apply gap-x-0 gap-y-0;
} */

/* @media screen(md) {
  body.wh
    .aaco-cut-grid
    .aaco-content-grid
    .aaco-card:not(.aaco-cut-card)
    .aaco-card__image,
  body.wh
    .aaco-cut-grid
    .aaco-content-grid
    .aaco-card:not(.aaco-cut-card)
    .aaco-card__content,
  body.wh
    .aaco-cut-grid
    .aaco-content-grid
    .aaco-card:not(.aaco-cut-card)
    .aaco-card__image
    + div {
    @apply mr-[60px];
  }
} */

body.wh
  .aaco-cut-grid
  .aaco-content-grid
  .aaco-card:not(.aaco-cut-card)
  .aaco-card__image {
  @apply mb-[15px] h-[418px] md:h-[340px] !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.aaco-cuts-illustrations__grid,
.aaco-cut-grid .grid {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.aaco-cut-grid .grid::-webkit-scrollbar {
  display: none;
}

body.wh .aaco-cut-card .aaco-card__content .aaco-cta,
body.wh .aaco-cut-grid .aaco-content-grid .aaco-card__content .aaco-cta {
  @apply text-black;
}

body.wh .aaco-cut-card .aaco-card__content .aaco-cta .aaco-icon-container {
  @apply text-acacia border-warm-grey;
}

.aaco-cut-grid .wh-grid-wrap .grid {
  column-gap: 40px !important;
}

.aaco-cut-grid .wh-grid-wrap .grid-cut-col {
  column-gap: 10px !important;
}

.aaco-cut-grid .wh-grid-wrap .grid-sequence {
  @apply grid-cols-1 md:grid-cols-content-grid-overflow-x;
}

.aaco-cut-grid .wh-grid-wrap .grid-sequence div {
  @apply m-0;
}

/* body.wh .aaco-cut-card .aaco-card__content {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05),
    0px 14.355555534362793px 31.244443893432617px 0px rgba(0, 0, 0, 0.05),
    0px 57.42222213745117px 57.42222213745117px 0px rgba(0, 0, 0, 0.04),
    0px 129.1999969482422px 77.68888854980469px 0px rgba(0, 0, 0, 0.02),
    0px 229.6888885498047px 92.04444885253906px 0px rgba(0, 0, 0, 0.01),
    0px 359.73333740234375px 100.4888916015625px 0px rgba(0, 0, 0, 0);
} */

@media (md) {
  /* body.wh .aaco-cut-card .aaco-card__content {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05),
      0px 14.355555534362793px 31.244443893432617px 0px rgba(0, 0, 0, 0.05),
      0px 57.42222213745117px 57.42222213745117px 0px rgba(0, 0, 0, 0.04),
      0px 129.1999969482422px 77.68888854980469px 0px rgba(0, 0, 0, 0.02),
      0px 229.6888885498047px 92.04444885253906px 0px rgba(0, 0, 0, 0.01),
      0px 359.73333740234375px 100.4888916015625px 0px rgba(0, 0, 0, 0);
  } */
}

body.wh .aaco-cuts-illustrations .swiper-slide {
  margin-right: 0;
}

body.wh .aaco-cuts-illustrations .swiper-wrapper {
  @apply md:pl-[12.5%];
}

body.wh .aaco-cuts-illustrations .swiper-button-disabled {
  @apply opacity-50;
}

body.wh .aaco-cuts-illustrations .swiper-control {
  @apply text-acacia;
}

body.wh .aaco-cuts-illustrations .swiper-button-lock {
  @apply block;
}

body.wh .aaco-cuts-illustrations img {
  @apply w-[90px] h-[90px];
}

/* footer margin top create weird white block on last part */
body.wh .app-container[aria-label='our-cuts'] .aaco-footer {
  margin-top: 0 !important;
}

body.wh [id='Our Cuts Quote'] {
  @apply justify-start;
}

body.wh [id='Our Cuts Quote'] .aaco-block-text {
  @apply text-left;
}

body.wh [id='Our Cuts Quote'] .aaco-paragraph--is-blockquote {
  @apply order-2;
}

body.wh [id='Our Cuts Quote'] p.aaco-paragraph--is-text span {
  @apply text-black !uppercase;
}
/* ========================== */
/*  END: our cuts pages       */
/* ========================== */

/* ========================== */
/*  START: our product pages  */
/* ========================== */

/* Banner text */
/* body.wh .app-container[aria-label='our-products'] .aaco-header__content {
  @apply pb-[72px] md:pb-[58px];
} */
/* body.wh .app-container[aria-label='our-products'] .aaco-header__content h1 {
  @apply font-heading text-h1-mobile md:text-h1 font-h1-mobile md:font-h1;
} */

/* Start: anchor navigation scroll on marrow & products */
/* body.wh .app-container[aria-label='our-products'] .aaco-header-wrapper {
  height: calc(100vh - var(--anchor-height));
  margin-bottom: 0;
} */

/* body.wh
  .app-container[aria-label='our-products']
  .aaco-header-wrapper
  + .aaco-anchor-navigation--scroll {
  margin-top: var(--anchor-height);
} */
/* End: anchor navigation scroll */

/* body.wh
  .app-container[aria-label='our-products']
  > *:not(#navigation__wrapper):not(.aaco-header):not(.aaco-anchor-navigation__container) {
  margin-top: 92px;
} */

/* body.wh .app-container[aria-label='our-products'] #navigation__wrapper {
  margin-bottom: 0 !important;
} */

/* Text */
/* body.wh
  .app-container[aria-label='our-products']
  .aaco-two-column-layout
  .aaco-column
  .aaco-block-text__wrapper
  p.aaco-paragraph--is-text
  span {
  @apply text-acacia text-body;
} */

/* Left column spacing */
/* body.wh .app-container[aria-label='our-products'] .aaco-left-column {
  @apply mb-10;
} */

/* body.wh
  .app-container[aria-label='our-products']
  .aaco-left-column
  > .aaco-section-header
  .aaco-section-header__title-wrapper {
  @apply mb-0;
}

Right column spacing
body.wh
  .app-container[aria-label='our-products']
  .aaco-right-column
  > .aaco-section-header
  .aaco-section-header__title-wrapper {
  @apply py-0 mb-10 md:mb-[30px];
}

body.wh
  .app-container[aria-label='our-products']
  .aaco-two-column-layout
  .aaco-right-column
  .aaco-block-text
  .aaco-paragraph--is-text
  > span {
  @apply text-acacia;
}

body.wh
  .app-container[aria-label='our-products']
  .aaco-two-column-layout
  .aaco-right-column
  .aaco-block-text__wrapper,
body.wh
  .app-container[aria-label='our-products']
  .aaco-two-column-layout
  .aaco-right-column
  .aaco-story-featured-asset,
body.wh .app-container[aria-label='our-products'] .aaco-content-grid
{
  @apply mb-0;
} */

/* body.wh
  .app-container[aria-label='our-products']
  .aaco-anchor-navigation.aaco-anchor-navigation--moving {
  @apply mb-[92px] md:mb-[134px];
} */

.aaco-left-column
  [id='Animal Walfare Content Text']
  .aaco-paragraph--is-text
  span {
  @apply !font-body !text-body;
}

[id='Column Content Animale Walfare']
  .aaco-right-column
  .aaco-paragraph--is-text
  span {
  @apply !font-body-large !text-body-large;
}

body.wh .aaco-story-product__text .aaco-block-text__wrapper {
  @apply m-0;
}

body.wh .aaco-story-product__text p {
  width: 250px;
}

body.wh .aaco-story-product__text p > span {
  @apply text-white !text-[10px] !leading-[13px] font-pro-mono uppercase;
  letter-spacing: normal !important;
}

body.wh .aaco-story-product__text .aaco-block-text__wrapper .aaco-block-text {
  @apply border-white border-l-1 pl-4;
}
/* ========================== */
/*  END: our product pages    */
/* ========================== */

/* ========================== */
/*  START: contact us pages    */
/* ========================== */

body.wh
  .app-container[aria-label='contact']
  .aaco-content-grid
  .aaco-section-header
  .header-container {
  @apply p-0;
}

[aria-label='contact']
  .content-grid-wrapper
  .wh-section-header
  .small-text-sub {
  @apply md:h-14;
}

/* ========================== */
/*  Start: static content    */
/* ========================== */

body.wh .grid-sequence .aaco-section-header {
  @apply p-0;
}
